import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TObjectType } from './interfaces/common';
import { getObjectDifference, targetIsNotEquals } from './utils';

const DEFAULT_DEBOUNCE_DELAY = 250;

export interface IAuth0AccessToken {
	scope: string;
	iat: number;
	exp: number;
}

// const useWindowSize = () => {
// 	const [windowSize, setWindowSize] = useState({
// 		width: window.innerWidth,
// 		height: window.innerHeight,
// 	});

// 	useEffect(() => {
// 		function handleResize() {
// 			setWindowSize({
// 				width: window.innerWidth,
// 				height: window.innerHeight,
// 			});
// 		}

// 		window.addEventListener('resize', handleResize);
// 		return () => window.removeEventListener('resize', handleResize);
// 	}, []);

// 	return windowSize;
// };

export const useScreenWidth = (width: number) => {
	const [isSmaller, setIsSmaller] = useState(window.innerWidth < width);

	useEffect(() => {
		function handleResize() {
			setIsSmaller(window.innerWidth < width);
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [width]);

	return [isSmaller];
};

// export const useAccessToken = (scope = DEFAULTS.accessPermissionScope, showError = true) => {
// 	const { isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup, loginWithRedirect } = useAuth0();

// 	const getAccessToken = useCallback(async () => {
// 		if (!isAuthenticated) return '';

// 		let accessToken: TStringWithUndefined;
// 		try {
// 			// accessToken = await getAccessTokenSilently(getTokenSilentlyAuthOptions(scope));
// 			accessToken = await getAccessTokenSilently();
// 		} catch (error) {
// 			try {
// 				accessToken = await getAccessTokenWithPopup();
// 			} catch (error) {
// 				loginWithRedirect({
// 					authorizationParams: {
// 						redirect_uri: window.location.href,
// 					},
// 				});
// 				return '';
// 			}
// 		}
// 		if (!accessToken) {
// 			if (showError) await showPromiseError(NEED_ACCESS_TOKEN);
// 			throw new Error(NEED_ACCESS_TOKEN);
// 		}
// 		return accessToken;
// 	}, [getAccessTokenSilently, getAccessTokenWithPopup, loginWithRedirect, showError, isAuthenticated]);

// 	return getAccessToken;
// };

// interface IUseAPIState<T> {
// 	error?: Error;
// 	loading: boolean;
// 	data?: T;
// }
// export const useAPI = <T>(
// 	url: string,
// 	fetchOptions?: RequestInit,
// 	scope = DEFAULTS.accessPermissionScope,
// 	showError = true
// ): [IUseAPIState<T>, () => void] => {
// 	const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
// 	const [state, setState] = useState<IUseAPIState<T>>({ loading: true });

// 	const update = async () => {
// 		let accessToken: TStringWithUndefined;
// 		try {
// 			accessToken = await getAccessTokenSilently();
// 		} catch (error) {
// 			accessToken = await getAccessTokenWithPopup();
// 		}
// 		let result: Omit<IUseAPIState<T>, 'loading'> = {};
// 		try {
// 			setState((state) => ({ ...state, loading: true }));
// 			const response = await fetch(url, {
// 				...fetchOptions,
// 				headers: {
// 					...fetchOptions?.headers,
// 					Authorization: `Bearer ${accessToken}`,
// 				},
// 			});
// 			if (!response.ok) {
// 				throw new Error(`${response.status} ${response.statusText}: ${await response.text()}`);
// 			}
// 			result.data = await response.json();
// 		} catch (error) {
// 			result.error = error as Error;
// 			if (showError) showSystemError(result.error);
// 		}
// 		setState({ ...result, loading: false });
// 	};

// 	useEffect(() => {
// 		update();
// 	}, [url, scope, fetchOptions, showError]); // eslint-disable-line
// 	return [state, update];
// };

export const useDebounce = <T>(initialValue: T, delay: number = 500) => {
	const [value, setValue] = useState(initialValue);
	const [debouncedValue, setDebouncedValue] = useState(initialValue);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return { value, setValue, debouncedValue };
};

export function useFormFields<T extends TObjectType>(initialFields: T) {
	const [formFields, setFormFields] = useState<T>({ ...initialFields });
	const [modified, setModified] = useState<boolean>(false);

	useEffect(() => {
		setFormFields({ ...initialFields });
	}, [initialFields]);

	useEffect(() => {
		setModified(targetIsNotEquals(initialFields, formFields));
	}, [initialFields, formFields]);

	/**
	 * За бізнес-логікою додатку поле типу boolean реалізовано за допомогою
	 * типу number, що може містити значення 1 або 0
	 */
	const createChangeHandler = useCallback(
		(key: keyof T, fieldType: 'string' | 'number' | 'boolean' = 'string') =>
			(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
				const value = event.target.value;
				// console.log((event.target as HTMLInputElement).checked);
				// return;

				setFormFields((prev: T) => ({
					...prev,
					[key]:
						fieldType === 'string'
							? value
							: fieldType === 'number'
							? parseInt(value || '0', 10)
							: (event.target as HTMLInputElement).checked
							? 1
							: 0,
				}));
			},
		[]
	);

	const resetFormFields = useCallback(() => {
		setFormFields({ ...initialFields });
	}, [initialFields]);

	return { formFields, createChangeHandler, setFormFields, resetFormFields, modified };
}

export const useNavigateToParams = <T extends TObjectType>(existingParams: T) => {
	const [params, setParams] = useState(existingParams);
	const navigate = useNavigate();

	useEffect(() => {
		setParams(existingParams);
	}, [existingParams]);

	const navigateToParams = useCallback(
		(additionParams: Partial<T>, DEFAULT_PARAMS: T, route = '/search') => {
			const newParams: T = {
				...params,
				...additionParams,
			};
			const newUrlSearchParams = new URLSearchParams(
				getObjectDifference(DEFAULT_PARAMS, newParams) as Record<string, string>
			).toString();
			navigate(`${route}?${newUrlSearchParams.toString()}`);
		},
		[navigate, params]
	);

	return navigateToParams;
};

// : [
// 	T,
// 	(
// 		key: keyof T,
// 		fieldType?: 'string' | 'number'
// 	) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void,
// 	React.Dispatch<React.SetStateAction<T>>
// ]

export const useQueryParam = <T extends TObjectType>(
	key: keyof T,
	defaultValue: string,
	delay = DEFAULT_DEBOUNCE_DELAY,
	replace = false
) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [queryParam, setQueryParam] = useState(searchParams.get(key as string) || defaultValue);
	// eslint-disable-next-line
	const { debouncedValue } = useDebounce(queryParam, delay);

	useEffect(() => {
		const newParam = searchParams.get(key as string) || defaultValue;
		if (newParam !== queryParam) setQueryParam(newParam);
	}, [searchParams, key]); // eslint-disable-line

	useEffect(() => {
		if (debouncedValue === undefined || debouncedValue === defaultValue) searchParams.delete(key as string);
		else searchParams.set(key as string, debouncedValue);
		setSearchParams(searchParams, { replace });
	}, [debouncedValue, key]); // eslint-disable-line

	const onQueryParamChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setQueryParam(event.target.value);
	}, []);

	return { queryParam, setQueryParam, searchParams, onQueryParamChange };
};
