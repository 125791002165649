import React from 'react';
import WithSearchResponse, { IWithSearchResponse } from '../../components/hoc/withSearchResponse';
import { IMessage } from '../../init';
import PaginationPanel from '../searcher/pagination';

/**
 * Компонент для рендерингу контейнеру з, власне, списком повідомлень.
 * Самі повідомлення рендеряться компонентом, що викликає даний.
 */

export interface IMessageListRenderProps {
	message: IMessage;
}

interface IMessageListProps extends IWithSearchResponse, React.AllHTMLAttributes<HTMLDivElement> {
	renderChild: (props: IMessageListRenderProps) => JSX.Element;
	withScrolling?: boolean;
}
const MessageList = ({ className, searchResponse, renderChild, withPagination, withScrolling }: IMessageListProps) => {
	if (!searchResponse) return null;
	return (
		<>
			{/* {error && <FetchError error={error} />} */}
			<div className={className}>{searchResponse.response?.docs.map((message) => renderChild({ message }))}</div>
			{withPagination && <PaginationPanel withScrolling={withScrolling} />}
		</>
	);
};

export default WithSearchResponse(MessageList);
